.gameOverModal {
  width: 50%;
  height: 50%;
  padding: 10vh;
  text-align: center;
  justify-content: center;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 1vh 1vh 5vh #888888;
  h1 {
    font-size: 5vh;
  }
  p {
    font-size: 3vh;
  }
}

.gameOverModalWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
}
