.scoreContainer {
  width: 20vw;
  height: 100vh;
  position: relative;
  background-color: grey;
  padding: 1vh;
  text-align: center;
  box-sizing: border-box;

  h1 {
    font-size: 5vh;
    margin: 0;
  }

  p {
    font-size: 3vh;
    margin: 0;
  }
}
