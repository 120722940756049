.gameField {
  width: 80vw;
  height: 100vh;
  position: relative;
}

.trapLine {
  width: 100%;
  height: 0.5%;
  background-color: #aaa;
  position: absolute;
}
